import React from "react"
import { Link } from "gatsby"

import Page from "~/blocks/Page/Page.js"
import Heading from "~/blocks/Heading/Heading.js"

import "~/helper-blocks/Content/Content.scss"

/*
	404 PAGE
*/
const NotFoundPage = () => {
	return (
		<Page centered={true}>
			<div className="grid grid--gap--1">
				<Heading level={1} className="h1">
					Erro 404
				</Heading>

				<div className="content">
					<p className="h5">A página que você tentou acessar não foi encontrada!</p>
					<p>Isso pode significar que:</p>
					<ul>
						<li>a página que você está procurando não existe mais ou está em outro endereço;</li>
						<li>você pode ter digitado errado o endereço da página.</li>
					</ul>
					<p>Se você não sabe o que fazer, clique no botão "voltar" do navegador ou <Link to="/">volte à página inicial do site.</Link></p>
				</div>
			</div>
		</Page>
	)
}

export default NotFoundPage